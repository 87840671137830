import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	description: {
    fontFamily: 'Roboto, san-serif',
    marginBottom: '2rem',
	},
	title: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		marginLeft: '1.2rem',
		fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.1,
    }
	},
  arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
}));

export const MobileBodyFeature = ({ linkSection }) => {
  const { description, links } = linkSection;
	const classes = useStyles();
	const xs = useMediaQuery('(max-width: 481px)');
  return (
    <Grid container direction='column'>
      <Typography variant='body1' className={classes.description}>
        {description}
      </Typography>
      {links.map(( link ) => (
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          style={{ marginBottom: '2rem' }}>
          <FontAwesomeIcon
            icon={['fad', link.homeLinkIcon ?? 'fish']}
            style={{
              height: xs ? '40px' : '60px',
              width: xs ? '40px' : '60px',
              border: `1px solid #E5EAF4`,
              background: '#E5EAF4',
              padding: '10px',
              borderRadius: '5px',
              color: link.linkIconColor?.hexValue ?? '#002D5C',
            }}
          />
     			<a
            href={link.slug.current}
            style={{
              textDecoration: 'none',
            }}>
            <Typography variant='h3' className={classes.title}>
              {link.title}
              <span className={classes.arrow}>→</span>
            </Typography>
          </a>
        </Grid>
      ))}
    </Grid>
  );
};