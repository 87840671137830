import React, { useState, useEffect, useRef } from 'react';

import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { DisplayedFeatureCard } from './DisplayedFeatureCard';
import { DisplayedFeatureSection } from './DisplayedFeatureSection';
import { MobileFeatureSection } from './Mobile/MobileFeatureSection';
import { ERPCallout } from './ERPCallout';

const FeatureLinks = ({
	homeLinksHeader,
	homeLinks = [],
	homeLinksCallout,
}) => {
	const med = useMediaQuery('(max-width: 960px)');
	const mobileCardRef = useRef(null);
	const [displayedFeatures, setDisplayedFeatures] = useState(null);
	const [expanded, setExpanded] = useState(0);

	useEffect(() => {
		setDisplayedFeatures(homeLinks[0]._key);
	}, []);

	const handleToggleDisplayed = (e, _key) => {
		e.preventDefault();
		setDisplayedFeatures(_key);
	};

	const handleChange = async (index) => {
		await setExpanded(index);
		mobileCardRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	return (
		<div
			style={{
				background: '#FAFCFF',
				padding: '4rem 0',
				boxShadow:
					'inset 0 40px 40px -40px rgba(0, 45, 92,0.10), inset 0 -40px 40px -40px rgba(0, 45, 92,0.10)',
				position: 'relative',
			}}>
			<Container>
				<div ref={mobileCardRef} />
				<div
					style={{
						paddingBottom: displayedFeatures === 'a7e418bf8919' ? '4rem' : '0',
					}}>
					<Grid
						container
						justifyContent={med ? 'flex-start' : 'center'}
						alignItems='center'
						xs={10}
						sm={12}>
						<Typography variant='h2' color='primary'>
							{homeLinksHeader}
						</Typography>
					</Grid>
					{!med ? (
						<>
							<Grid
								container
								justifyContent='center'
								alignItems='center'
								style={{ padding: '2rem 0' }}>
								{homeLinks.map((link, index) => (
									<DisplayedFeatureCard
									  role="button"
				            tabIndex={0}
										key={index}
										link={link}
										displayedFeatures={displayedFeatures}
										handleClick={handleToggleDisplayed}
									/>
								))}
							</Grid>
							{homeLinks
								.filter((link) => link._key === displayedFeatures)
								.map((link) => (
									<DisplayedFeatureSection link={link} key={link._key} />
								))}
						</>
					) : (
						<div style={{ marginTop: '1rem' }}>
							{homeLinks.map((link, index) => (
								<MobileFeatureSection
									link={link}
									key={link._key}
									index={index}
									expanded={expanded}
									handleChange={handleChange}
								/>
							))}
						</div>
					)}
				</div>
				<ERPCallout homeLinksCallout={homeLinksCallout} />
			</Container>
		</div>
	);
};

export default FeatureLinks;
