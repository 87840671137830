import React from 'react';

import {
	Grid,
	Typography,
	makeStyles,
	Button,
	useMediaQuery,
} from '@material-ui/core';
import { navigate } from 'gatsby';

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.white,
		border: '2px solid #E5EAF4',
		borderRadius: '8px',
		boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.05)',
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	button: {
		background: '#2A7ABC',
		color: '#fff',
		padding: '1.6rem 2rem',
		opacity: 1,
		fontSize: '1.125rem',
		fontWeight: 700,
		transition: 'all 0.35s ease !important',
		borderRadius: '0px 8px 8px 0px',
		textTransform: 'capitalize',
		'&:hover': {
			background: '#2A7ABC',
			opacity: '0.85 !important',
			'& $arrow': { transform: 'translateX(10px)' },
		},
		[theme.breakpoints.down('md')]: {
			margin: '1rem 0 1rem 1rem',
			padding: '16px 32px',
			borderRadius: '8px',
		},
	},
}));

export const ERPCallout = ({ homeLinksCallout }) => {
	const classes = useStyles();
	const { header, description, ctaText, ctaLink } = homeLinksCallout;

	const handleClick = (e, slug) => {
		e.preventDefault();
		navigate(`/${slug}`);
	};

	const lg = useMediaQuery('(max-width: 1280px)');
	return (
		<Grid container className={classes.root} justifyContent='space-between'>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='space-evenly'
				item
				lg={9}>
				<Typography
					variant='h3'
					color='secondary'
					style={{ marginTop: '1rem', marginLeft: '1rem', fontSize: '1.3rem' }}>
					{header}
				</Typography>
				<Typography
					variant='body2'
					style={{
						margin: '.5rem 0 1rem 1rem',
						fontFamily: 'Roboto, sans-serif',
					}}>
					{description}
				</Typography>
			</Grid>
			<Grid
				item
				lg={3}
				container
				justifyContent={lg ? 'flex-start' : 'flex-end'}
				alignItems='center'>
				<Button
					variant='contained'
					size='large'
					onClick={(e) => handleClick(e, ctaLink)}
					className={classes.button}>
					{ctaText}
					<span className={classes.arrow}>→</span>
				</Button>

				{/* <Typography variant='body1' style={{ color: '#FFF', fontWeight: 700 }}>
					{ctaText}
					<span className={classes.arrow}>→</span>
				</Typography> */}
			</Grid>
		</Grid>
	);
};
