import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '1.3rem',
		fontWeight: 'bold',
		color: theme.workwaveBlue,
		textAlign: 'center',
		lineHeight: '1',
		margin: '0 auto',
		position: 'absolute',
		padding: '0 5px',
		left: '50px',
		right: '0',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
		},
	},
	card: {
		// border: '1px solid #ECECEE',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
		borderRadius: '8px',
		cursor: 'pointer',
		background: theme.white,
		width: '100%',
		transition: 'transform 0.25s, box-shadow 0.75s',
		margin: '0 auto',
		position: 'relative',
		border: '2px solid #E5EAF4',
		paddingRight: '30px',
		'&:last-of-type': {
			paddingRight: '0px',
		},
	},
	cardGrid: {
		marginBottom: '30px',
		paddingRight: '30px',
		'&:last-of-type': {
			paddingRight: '0px',
		},
	},
	cardMargin: {
		marginBottom: '26px',
	},
}));

export const DisplayedFeatureCard = ({
	link,
	displayedFeatures,
	handleClick,
}) => {
	const classes = useStyles();
	return (
		<Grid
			style={{ padding: '0 14px' }}
			item
			container
			xs={12}
			md={4}
			className={classes.cardMargin}>
			<div
				className={classes.card}
				role="button"
				tabIndex={0}
				style={
					displayedFeatures === link._key
						? {
								// border: '1px solid #ECECEE',
								// transform: 'scale(1.04)',
								transition: 'transform 0.25s, box-shadow 0.75s',
								boxShadow:
									'0 16px 30px -8px rgba(50, 50, 93, 0.2), 0 11px 19px -11px rgba(0, 0, 0, 0.3)',
						  }
						: null
				}>
				<Grid
					onClick={(e) => handleClick(e, link._key)}
					role="button"
				  tabIndex={0}
					container
					direction='row'
					justifyContent='flex-start'
					alignItems='center'>
					<FontAwesomeIcon
						icon={['fad', link.buttonIcon]}
						style={{
							height: '50px',
							width: '50px',
							border: `1px solid #E5EAF4`,
							background: '#E5EAF4',
							padding: '6px',
							color: link.buttonIconColor?.hexValue ?? '#002D5C',
							// borderRadius: '8px 0 0 8px',
						}}
					/>
					<Typography className={classes.title}>{link.buttonTitle}</Typography>
				</Grid>
			</div>
		</Grid>
	);
};
