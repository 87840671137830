import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { FeatureLink } from './FeatureLink';

export const DisplayedFeatureSection = ({ link }) => {
	const { header, description, image, links } = link;
  const lg = useMediaQuery('(max-width: 1280px)');
	return (
		<Grid container direction='row'>
			<Grid container direction='column' item lg={6}>
				<Typography variant='h3' color='primary'>
					{header}
				</Typography>
				<Typography variant='body1'>{description}</Typography>
				<Grid container spacing={2} style={{ marginTop: '2rem' }}>
					{links.map((link, index) => (
						<FeatureLink link={link} key={index} />
					))}
				</Grid>
			</Grid>
			<Grid container direction='column' item lg={6}>
				<GatsbyImage
					image={image?.asset.gatsbyImageData}
					imgStyle={{ objectFit: 'contain' }}
          style={{ marginTop: lg ? '3rem' : 'initial' }}
				/>
			</Grid>
		</Grid>
	);
};
