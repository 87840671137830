import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
} from '@material-ui/core';

import { MobileBodyFeature } from './MobileBodyFeature';

// import { BodyFeature } from '../BodyFeature';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		marginLeft: '1.2rem',
		fontSize: '1.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '75%',
      lineHeight: 1.1,
    }
	},
	card: {
		// border: '1px solid #ECECEE',
		boxShadow: '0px .5px 2px rgba(0, 0, 0, 0.3)',
		borderRadius: '3px',
		cursor: 'pointer',
		background: theme.white,
		transition: 'transform 0.25s, box-shadow 0.75s',
	},
	expanded: {},
	root: {
		'&$expanded': {
			minHeight: 0,
		},
	},
	content: {
		margin: 0,
		'&$expanded': {
			margin: 0,
			minHeight: 'unset',
		},
	},
}));

export const MobileFeatureSection = ({link, index, expanded, handleChange}) => {
	const { header, buttonIcon, buttonIconColor } = link;

	const classes = useStyles();
	return (
		<Grid item xs={12} style={{ marginBottom: index === 0 ? '2rem' : 0 }}>
			<Accordion
				expanded={expanded === index ? true : false}
				className={classes.card}
				onChange={() => handleChange(index)}
				style={{
					borderRadius: '3px',
					border: '1px solid #E5EAF4',
					boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
				}}>
				<AccordionSummary
					style={{ padding: 0 }}
					classes={{
						content: classes.content,
						expanded: classes.expanded,
						root: classes.root,
					}}>
					<Grid
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'>
						<FontAwesomeIcon
							icon={['fad', buttonIcon ?? 'fish']}
							style={{
								height: '60px',
								width: '60px',
								border: `1px solid #E5EAF4`,
								background: '#E5EAF4',
								padding: '10px',
								color: buttonIconColor?.hexValue ?? '#002D5C',
							}}
						/>

						<Typography variant='h5' className={classes.title}>
							{header}
						</Typography>
					</Grid>
				</AccordionSummary>
				 <AccordionDetails>
          <MobileBodyFeature linkSection={link} />
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
};
